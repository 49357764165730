@charset "utf-8";

.c-button-to-page {
	display: flex;
	justify-content: center;
	.item {
		width: 400px;
		a {
			height: 80px;
			color: $mainColor;
			font-size: 20px;
			font-weight: 700;
			border: 1px solid $mainColor;
			border-radius: 100vh;
			background-color: transparent;
			display: flex;
			justify-content: center;
			align-items: center;
			letter-spacing: 0.08em;
			transition: 0.3s;
			&:hover {
				color: #FFF;
				background-color: $mainColor;
			}
		}
	}
}
@include media(small) {
	.c-button-to-page {
		.item {
			width: 90%;
			a {
				height: 50px;
				font-size: 18px;
				&:hover {
					color: $mainColor;
					background-color: transparent;
				}
			}
		}
	}
}



a.c-link-text {
	color: $mainColor;
	&:hover {
		opacity: 0.6;
	}
}
