@charset "utf-8";

body {
	counter-reset: number 0;
}

.page {
	.wrapper {
		opacity: 0;
		&.show {
			opacity: 1;
			transition: 0.8s;
		}
	}

	#loading {
		&.hidden_animation {
			transition-delay: 0.3s;
		}
	}
	.header_logo {
		position: absolute;
		top: 18px;
		left: 50%;
		transform: translate(-50%, 0);
		z-index: 2;
		a {
			width: 110px;
			display: block;
			opacity: 1;
			position: relative;
			z-index: 0;
			.svg__fg_logo03 {
			}
			&:hover {
				.svg__fg_logo03 {
					fill: $mainColor;
					transition: 0.3s;
				}
			}
		}
	}
	main {
		padding: 150px 0 200px 0;
		display: block;
		position: relative;
		z-index: 1;
		.container {
			padding: 0;
		}
		.section {
			.section_title {
				border-top: 1px solid $mainColor;
				border-bottom: 1px solid $mainColor;
				padding-top: 20px;
				padding-bottom: 20px;
				.ja {
					font-size: 26px;
				}
				.eng {
					font-size: 18px;
				}
			}
		}
	}
	@include media (small) {
		#loading {
			&.hidden_animation {
			}
		}
		.header_logo {
			top: 11px;
			a {
				width: 70px;
				&::after {
					display: none;
				}
				&:hover {
					.svg__fg_logo03 {
						fill: #231815;
					}
				}
			}
		}
		main {
			padding: 25% 0 25% 0;
			.container {
			}
			.section {
				.section_title {
					padding-top: 4%;
					padding-bottom: 4%;
					.ja {
						font-size: 5vw;
					}
					.eng {
						font-size: 3.8vw;
					}
				}
			}
		}
	}
}



.l-service {
	&__section {
		padding-bottom: 60px;
		&:last-of-type {
			padding-bottom: 0;
		}
	}
	&__navigation {
		margin-bottom: 100px;
	}
	&__title {
		font-size: 36px;
		text-align: center;
		font-weight: 700;
		line-height: 1.4;
		margin-bottom: 40px;
		color: #FF7537;
	}
	&__mv {
		padding-top: 30px;
		margin-bottom: 40px;
		img {
			width: 100%;
			height: auto;
		}
	}
	&__mv-lead {
		padding-bottom: 60px;
		border-bottom: 1px solid $mainColor;
		p {
			font-size: 16px;
			text-align: center;
			letter-spacing: 0;
			line-height: 1.8;
		}
	}
	&__title-sub {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 2em;
		margin-bottom: 2em;
		.large,
		.min {
			display: block;
		}
		.large {
			font-size: 20px;
			font-weight: 700;
			white-space: nowrap;
		}
		.min {
			font-size: 12px;
		}
	}
	&__detail-wrap {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		.item {
			width: 25%;
			background-color: #EEEEEE;
			padding: 19px;
			border: 1px solid #FFF;
			.item-title {
				.ja,
				.eng {
					display: block;
					font-weight: 700;
				}
				.ja {
					font-size: 16px;
					padding-bottom: 0.4em;
					white-space: nowrap;
					line-height: 1.3;
				}
				.eng {
					color: #FF7537;
					font-size: 10px;
					text-transform: none;
					padding-bottom: 0.5em;
				}
			}
			.item-text {
				p {
					font-size: 12px;
					letter-spacing: 0.034em;
					line-height: 1.6;
					text-align: justify;
				}
			}
		}
	}

	&__article-list {
		padding-top: 50px;
		.l-service__mv-lead {
			padding-bottom: 40px;
		}
		.service-article-wrap {
			padding-top: 40px;
			.title {
				font-size: 16px;
				font-weight: 700;
				text-align: center;
				padding-bottom: 40px;
			}
			.article-item {
				display: flex;
				justify-content: flex-start;
				padding-bottom: 60px;
				&:last-of-type {
					padding-bottom: 0;
				}
				.photo {
					display: block;
					width: 100%;
					max-width: 300px;
					height: 200px;
					background-color: #CCCCCC;
					position: relative;
					transition: 0.3s;
					pointer-events: none;
					&::after {
						counter-increment: number 1;
						content: "POINT 0"counter(number);
						width: 100px;
						height: 40px;
						padding-top: 6px;
						color: #FFF;
						font-size: 12px;
						display: flex;
						justify-content: center;
						align-items: center;
						background-color: rgba(255, 81, 0, 0.8);
						position: absolute;
						top: -20px;
						left: -20px;
					}
					&:hover {
						opacity: 0.7;
					}
					img {
						width: 100%;
						height: auto;
					}
				}
				.text {
					height: 200px;
					border: 1px solid #CCCCCC;
					padding: 10px 30px;
					.article-title {
						font-size: 16px;
						font-weight: 700;
						padding: 0.5em 0;
						margin-bottom: 0.2em;
						display: inline-block;
						transition: 0.3s;
						pointer-events: none;
						&:hover {
							color: $mainColor;
						}
					}
					.article-desc {
						font-size: 12px;
						line-height: 1.6;
					}
				}
			}
		}
	}
}
@include media (small) {
	.l-service {
		&__section {
			padding-bottom: 8%;
			&:last-of-type {
				padding-bottom: 0;
			}
		}
		&__navigation {
			margin-bottom: 8%;
		}
		&__title {
			font-size: 4.5vw;
			margin-bottom: 8%;
		}
		&__mv {
			padding-top: 0;
			margin-bottom: 4%;
		}
		&__mv-lead {
			padding-bottom: 8%;
			p {
				font-size: 14px;
			}
		}
		&__title-sub {
			display: block;
			margin-bottom: 4%;
			.large {
				font-size: 18px;
			}
		}
		&__detail-wrap {
			.item {
				width: 100%;
				padding: 15px;
				.item-title {
					.ja {
						font-size: 15px;
						padding-bottom: 0.1em;
					}
					.eng {
						padding-bottom: 0.3em;
					}
				}
			}
		}

		&__article-list {
			padding-top: 8%;
			.l-service__mv-lead {
				padding-bottom: 6%;
			}
			.service-article-wrap {
				padding-top: 6%;
				.title {
					font-size: 4vw;
					padding-bottom: 10%;
					line-height: 1.5;
				}
				.article-item {
					display: block;
					padding-bottom: 8%;
					.photo {
						max-width: 100%;
						height: auto;
						aspect-ratio: 600 / 400;
						&::after {
							width: 24vw;
							height: 10vw;
							padding-top: 1%;
							font-size: 3vw;
							top: -5%;
							left: -3%;
						}
						&:hover {
							opacity: 1;
						}
					}
					.text {
						height: auto;
						padding: 15px;
						.article-title {
							font-size: 14px;
							line-height: 1.5;
							padding: 0 0;
							margin-bottom: 0.4em;
						}
						.article-desc {
							font-size: 10px;
						}
					}
				}
			}
		}
	}
}
