@charset "utf-8";

.c-bnr-wp {
	width: 100%;
	margin: auto;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	p {
		padding: 0;
	}
	.u-black {
		color: #000;
	}
	.u-main {
		color: $mainColor;
	}
	.u-inlineblock {
		display: inline-block;
		position: relative;
	}
	.date {
		width: 100%;
		font-size: 12px;
		position: absolute;
		bottom: -0.3em;
		left: -2.3em;
		text-align: center;
		line-height: 1em;
	}
	.title {
		display: inline-block;
		padding: 0 1em;
		margin-bottom: 0.2em;
		font-size: 36px;
		font-weight: bold;
		text-align: center;
		line-height: 1.6;
		position: relative;
		letter-spacing: 0;
		&::before,
		&::after {
			content: "";
			display: block;
			width: 4px;
			height: 80%;
			background-color: $mainColor;
			position: absolute;
			bottom: 0;
		}
		&::before {
			left: 0;
			transform: rotate(-18deg);
		}
		&::after {
			right: 0;
			transform: rotate(18deg);
		}
	}
	.content-box {
		width: 100%;
		max-width: 750px;
		border: 4px solid $mainColor;
		border-radius: 8px;
		background-color: #FFE4D8;
		padding-bottom: 40px;
		.text {
			font-size: 20px;
			text-align: center;
			line-height: 1.4;
			padding: 26px 0;
			background-color: #FFF;
			border-top-right-radius: 8px;
			border-top-left-radius: 8px;
			letter-spacing: 0;
			.text-br {
				display: block;
			}
		}
		.catch {
			font-size: 28px;
			font-weight: bold;
			text-align: center;
			line-height: 1.3;
			padding: 26px 0;
			letter-spacing: 0;
			.catch-br {
				display: block;
			}
		}
		.link {
			display: block;
			width: 540px;
			margin: auto;
			opacity: 1;
			img {
				width: 100%;
				height: 450px;
				&.large {
					display: block;
				}
				&.min {
					display: none;
				}
			}
			&:hover {
				opacity: 0.7;
			}
		}
	}
}
@include media (small) {}



.page-banner-wp {
	width: 100%;
	max-width: 800px;
	margin: auto;
	padding-top: 85px;
	.c-bnr-wp {
		.date {
			font-size: 10px;
		}
		.title {
			padding: 0 0.6em;
			margin-bottom: 0.5em;
			font-size: 26px;
			.br {
				display: none;
			}
			&::before,
			&::after {
				width: 3px;
			}
		}
		.content-box {
			max-width: 100%;
			padding-bottom: 28px;
			.text {
				font-size: 14px;
				padding: 18px 0;
				.text-br {
					display: none;
				}
			}
			.catch {
				font-size: 16px;
				line-height: 1.3;
				padding: 18px 0;
				.catch-br {
					display: none;
				}
			}
			.link {
				width: 728px;
				img {
					width: 100%;
					height: auto;
					&.large {
						display: none;
					}
					&.min {
						display: block;
					}
				}
			}
		}
	}
}
@include media (small) {
	.page-banner-wp {
		width: 100%;
		max-width: 100%;
		padding-top: 10%;
		.c-bnr-wp {
			.date {
				font-size: 2.5vw;
				bottom: -0.5em;
			}
			.title {
				padding: 0 0.8em;
				margin-bottom: 0.5em;
				font-size: 5vw;
				line-height: 1.8;
				.br {
					display: block;
				}
				&::before,
				&::after {
					width: 2px;
				}
			}
			.content-box {
				max-width: 90%;
				padding-bottom: 5%;
				border-width: 2px;
				.text {
					font-size: 2.5vw;
					padding: 3.5% 0;
					line-height: 1.6;
					white-space: nowrap;
					.text-br {
						display: block;
					}
				}
				.catch {
					font-size: 3.4vw;
					line-height: 1.5;
					white-space: nowrap;
					padding: 3.5% 0;
					.catch-br {
						display: block;
					}
				}
				.link {
					width: 88%;
					img {
						&.large {
							display: block;
						}
						&.min {
							display: none;
						}
					}
				}
			}
		}
	}
}



.p-index-banner-wp {
	.c-popup__main {
		width: 82vh;
		max-width: 100%;
	}
	.c-popup__btn-close {
    width: 5.6vh;
    height: 5.6vh;
    top: 11vh;
    right: -2vh;
	}
	.c-bnr-wp {
		.date {
			font-size: 1.4vh;
			bottom: -0.3em;
			left: -2.3em;
		}
		.title {
			padding: 0 0.8em;
			font-size: 4vh;
			line-height: 1.6;
			&::before,
			&::after {
				height: 10vh;
			}
		}
		.content-box {
			width: 82vh;
			max-width: 100%;
			padding-bottom: 4vh;
			.text {
				font-size: 2vh;
				padding: 1.2em 0;
				.text-br {
					display: block;
				}
			}
			.catch {
				font-size: 2.8vh;
				padding: 0.7em 0;
				.catch-br {
					display: block;
				}
			}
			.link {
				width: 60vh;
				max-width: 540px;
				img {
					width: 100%;
					height: auto;
				}
			}
		}
	}
}
@include media (small) {
	.p-index-banner-wp {
		.c-popup__main {
			width: 92%;
			max-width: 100%;
		}
		.c-popup__btn-close {
			width: 9vw;
			height: 9vw;
			position: fixed;
			top: 3vw;
			right: 3vw;
			&:hover {
				background-color: #000;
			}
		}
		.c-bnr-wp {
			.date {
				font-size: 2.5vw;
				bottom: -0.5em;
			}
			.title {
				padding: 0 0.8em;
				margin-bottom: 0.5em;
				font-size: 5vw;
				line-height: 1.8;
				.br {
					display: block;
				}
				&::before,
				&::after {
					width: 2px;
					height: 70%;
				}
			}
			.content-box {
				width: 100%;
				max-width: 100%;
				padding-bottom: 5%;
				border-width: 2px;
				.text {
					font-size: 2.5vw;
					padding: 3.5% 0;
					line-height: 1.6;
					white-space: nowrap;
					.text-br {
						display: block;
					}
				}
				.catch {
					font-size: 3.4vw;
					line-height: 1.5;
					white-space: nowrap;
					padding: 3.5% 0;
					.catch-br {
						display: block;
					}
				}
				.link {
					width: 88%;
					max-width: 100%;
					img {
						&.large {
							display: block;
						}
					}
				}
			}
		}
	}
}
