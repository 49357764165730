@charset "utf-8";

.footer {
	position: relative;
	background: $subColor;
	color: #FFF;
	a {
		color: #FFF;
	}
	.container {
		margin: 0 auto;
		padding: 120px 5%;
		.box {
			width: 100%;
			max-width: 1000px;
			margin: auto;
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			position: relative;
			&::after {
				content: "";
				width: 1px;
				height: 120%;
				background-color: rgba($color: #FFF, $alpha: 0.7);
				position: absolute;
				top: -20px;
				left: 240px;
				transform: rotate(20deg);
			}
			.footer_nav {
				width: 61%;
				position: relative;
				.item {
					margin-bottom: 0.5em;
					&:last-child {
						margin-bottom: 0;
					}
					a {
						width: 130px;
						height: 28px;
						background: url(../images/common/btn_arrow_red.svg) no-repeat;
						background-size: 6px;
						background-position: left center;
						padding: 0 0 0 14px;
						display: block;
						position: relative;
						.eng,
						.ja {
							display: block;
							height: 28px;
							line-height: 25px;
							font-size: 16px;
							position: absolute;
							top: 50%;
							left: 15px;
							transform: translate(0, -50%);
						}
						.eng {
							letter-spacing: 0.1em;
							transition: 0.3s;
							line-height: 31px;
							visibility: visible;
							opacity: 1;
						}
						.ja {
							letter-spacing: 0.05em;
							transition: 0.8s;
							visibility: hidden;
							opacity: 0;
							transform: translate(-10px, -50%);
						}
						&:hover {
							.eng {
								color: $mainColor;
								visibility: hidden;
								opacity: 0;
								transform: translate(10px, -50%);
							}
							.ja {
								visibility: visible;
								opacity: 1;
								transform: translate(0, -50%);
							}
						}
					}
				}
				.nav_list__sub {
					padding-bottom: 3px;
					.sub-item {
						a {
							display: inline-block;
							width: auto;
							height: auto;
							padding-right: 0;
							font-size: 12px;
							text-align: left;
							white-space: nowrap;
							background: none;
							&::after {
								display: none;
							}
							&:hover {
								color: $mainColor;
							}
						}
					}
				}
			}
			.contact_block {
				width: 39%;
				.eng {
					font-size: 13px;
					letter-spacing: 0.1em;
					padding-bottom: 5px;
				}
				.btn_contact {
					width: 100%;
					height: 80px;
					line-height: 85px;
					text-align: center;
					background-image: url(../images/common/btn_arrow_white.svg);
					background-repeat: no-repeat;
					background-size:  32px;
					background-position:  right 26px top 32px;
					background-color: $mainColor;
					padding: 0;
					border-radius: 2px;
					overflow: hidden;
					display: block;
					position: relative;
					&::after {
						content: "";
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 0 0 20px 20px;
						border-color: transparent transparent $subColor transparent;
						position: absolute;
						bottom: 0;
						right: 0;
					}
					.eng {
						display: block;
						font-size: 22px;
						padding-bottom: 0;
					}
					&:hover {
						opacity: 0.6;
						background-position:  right 18px top 32px;
						transition: 0.4s;
					}
				}
			}
			.logo {
				width: 100%;
				border-bottom: 1px solid rgba($color: #FFF, $alpha: 0.7);
				margin: 40px 0 20px 0;
				img {
					width: 39%;
					margin-left: auto;
				}
			}
			.sns_block {
				visibility: hidden;
				opacity: 0;
				width: 12%;
				.btn_list {
					display: flex;
					justify-content: space-between;
					align-items: center;
					.btn {
						width: 30px;
						a {
							display: block;
							width: 100%;
							img {
								width: 100%;
							}
							&:hover {
								transform: scale(1.2);
							}
						}
					}
				}
			}
			.mark_block {
				margin-left: 100px;
				.mark_list {
					display: flex;
					justify-content: space-between;
					align-items: center;
					.item {
						margin-left: 20px;
						img {
							width: 100%;
						}
						&.mark_iso {
							width: 112px;
						}
						&.mark_p {
							width: 49px;
						}
					}
				}
			}
			.copyright,
			.invoice {
				width: 100%;
				text-align: right;
				font-size: 12px;
				height: 12px;
				line-height: 12px;
				letter-spacing: 0.1em;
				font-weight: normal;
				margin-left: auto;
			}
			.invoice {
				margin-bottom: 1.5em;
			}
			.link_policy {
				display: flex;
				justify-content: space-between;
				margin-left: auto;
				.item {
					margin-left: 20px;
					a {
						display: block;
						font-size: 13px;
						color: #FFF;
						letter-spacing: 0.05em;
						background: url(../images/common/btn_arrow_red.svg) no-repeat;
						background-size: 6px;
						background-position: left top 7px;
						padding-left: 14px;
						&:hover {
							color: rgba($color: #FFF, $alpha: 0.7);
							background-position: left 4px top 7px;
						}
					}
				}
			}
		}
	}

	@include media(small) {
		.container {
			width: 90%;
			padding: 10% 0;
			.box {
				align-items: center;
				&::after {
					display: none;
				}
				.footer_nav {
					width: 100%;
					margin-bottom: 6%;
					z-index: 1;
					.item {
						a {
							width: 160px;
							background-size: 6px;
							padding: 0 0 0 14px;
							.eng {
								font-size: 13px;
							}
							.ja {
								display: none;
							}
							&:hover {
								.eng {
									visibility: visible;
									opacity: 1;
									transform: translate(0, -50%);
									color: #FFF;
								}
							}
						}
					}
					.nav_list__sub {
						.sub-item {
							a {
								&:hover {
									color: #FFF;
								}
							}
						}
					}
				}
				.contact_block {
					width: 100%;
					margin-bottom: 8%;
					z-index: 1;
					.eng {
						font-size: 10px;
						padding-bottom: 3px;
					}
					.btn_contact {
						width: 80%;
						max-width: 300px;
						height: 70px;
						line-height: 70px;
						background-size:24px;
						background-position: right 26px top 26px;
						.eng {
							font-size: 16px;
						}
						&:hover {
							opacity: 1;
							background-position: right 26px top 26px;
						}
					}
				}
				.logo {
					margin: 0 0 4% 0;
					z-index: 1;
					img {
						width: 60%;
						margin-bottom: -1px;
					}
				}
				.sns_block {
					display: none;
					width: 120px;
					margin-left: 2px;
					z-index: 1;
					.btn_list {
						.btn {
							width: 30px;
							a {
								&:hover {
									transform: scale(1);
								}
							}
						}
					}
				}
				.mark_block {
					width: 100%;
					margin-left: 0;
					margin-bottom: 17px;
					.mark_list {
						width: 100%;
						margin-left: auto;
						justify-content: flex-end;
						.item {
							&.mark_iso {
								width: 75px;
							}
							&.mark_p {
								width: 30px;
							}
						}
					}
				}
				.copyright,
				.invoice {
					font-size: 12px;
					z-index: 1;
				}
				.invoice {
					font-size: 11px;
					margin-top: 1em;
					margin-bottom: 1.3em;
				}
				.link_policy {
					display: block;
					margin-top: 15px;
					.item {
						margin-left: 0;
						margin-bottom: 5px;
						&:last-child {
							margin-bottom: 0;
						}
						a {
							font-size: 12px;
							background-size: 5px;
							background-position: left top 8px;
							padding-left: 12px;
							&:hover {
								color: #FFF;
								background-position: left top 8px;
							}
						}
					}
				}
			}
		}
	}
}


#to-top {
	margin: 0;
	right: 20px;
	z-index: 20;
	transform: translate(0, 0);
	transition: 0.4s;
	opacity: 0;
	&:hover {
		opacity: 1;
		transform: translate(0, -15px);
		transition: 0.4s;
	}
	&.fadein {
		opacity: 1;
	}
	a {
		display: block;
		width: 77px;
		height: 77px;
		padding: 0 0;
		opacity: 1;
	}
	@include media(small) {
		right: 10px;
		opacity: 0;
		&:hover {
			opacity: 1;
			transform: none;
		}
		a {
			width: 45px;
			height: 45px;
		}
	}
}
