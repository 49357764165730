@charset "utf-8";

.c-popup {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10000 !important;
	display: flex;
	justify-content: center;
	align-items: center;
	&__bg {
		background-color: rgba(255, 255, 255, 0.8);
		backdrop-filter: blur(3px);
		width: 100%;
		height: 100%;
		position: fixed;
		z-index: 10001 !important;
	}
	&__main {
		width: 80%;
		max-width: 750px;
		height: auto;
		margin: auto;
		background-color: transparent;
		z-index: 10002 !important;
		position: relative;
	}
	&__btn-close {
		width: 50px;
		height: 50px;
		border-radius: 100vh;
		background-color: #000;
		color: #FFF;
		position: absolute;
		top: 100px;
		right: -23px;
		opacity: 1;
		z-index: 2;
		cursor: pointer;
		transition: 0.3s;
		&::before,
		&::after {
			content: "";
			display: block;
			width: 45%;
			height: 1px;
			background-color: #FFF;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) rotate(45deg);
			transition: 0.3s;
		}
		&::after {
			transform: translate(-50%, -50%) rotate(-45deg);
		}
		&:hover {
			background-color: $mainColor;
			transition: 0.3s;
			&::before,
			&::after {
				background-color: #FFF;
				transition: 0.3s;
			}
		}
	}
}
@include media (small) {
	.c-popup {
		overflow: auto;
		// padding: 8% 0;
	}
}
