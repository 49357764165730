@charset "utf-8";

#home {
	@include media(small) {
	}
}

#main-visual {
	width: 100%;
	background: url(../images/home/main_visual/bg.svg) no-repeat;
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	position: relative;
	opacity: 1;
	transition: 1s $easeInOutCubic;
	display: none !important;
	&::after {
		content: "";
		width: 100%;
		height: 100%;
		background-color: #FFF;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 1;
	}
	&.show {
		&::after {
			opacity: 0;
			transition: 0.6s;
			transition-delay: 1s;
		}
	}
	.container {
		width: 100%;
		max-width: 100%;
		padding: 0;
		z-index: 1;
		.box {
			width: 100%;
			max-width: 100%;
			height: 100vh;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0;
			.logo {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
				min-width: 400px;
				overflow: hidden;
				img {
					width: 100%;
				}
				.fg_logo__mark {
					width: 8%;
					margin-right: 1%;
					transform: translate(0, -20%);
					opacity: 0;
				}
				.fg_logo__type {
					width: 35%;
					transform: translate(-20px, 0);
					opacity: 0;
				}
				&.show {
					.fg_logo__mark {
						animation: mv_logo_show 1.2s forwards;
						animation-timing-function: $easeInOutCubic;
						animation-delay: 0.15s;
						@keyframes mv_logo_show {
							0% {
								opacity: 0;
								transform: translate(0, -35%);
							}
							50% {
								opacity: 1;
								transform: translate(0, -10%);
							}
							100% {
								opacity: 1;
								transform: translate(0, -20%);
							}
						}
					}
					.fg_logo__type {
						transform: translate(0, 0);
						opacity: 1;
						transition: 0.9s;
					}
				}
			}
		}
	}
	@include media(small) {
		background-position: center;
		background-position: center right;
		.container {
			.box {
				.logo {
					width: 80%;
					min-width: auto;
					.fg_logo__mark {
						width: 18%;
						margin-right: 2%;
					}
					.fg_logo__type {
						width: 79%;
					}
				}
			}
		}
	}
}

.first__img_arrow {
	width: 3%;
	max-width: 40px;
	height: auto;
	position: fixed;
	top: 25vh;
	right: 1%;
	z-index: 200;
	transform: translateY(0);
	transition: 0.3s ease-out;
	@include media(small) {
		position: absolute;
		width: 6%;
		top: 11vh;
		right: 2%;
	}
}

#vision {
	background: url(../images/home/vision/bg.jpg) no-repeat;
	background-size: cover;
	background-position: bottom center;
	background-attachment: fixed;
	color: #FFF;
	.container {
		width: 100%;
		max-width: 100%;
		padding: 0;
		display: flex;
		justify-content: space-between;

		.vision_nav {
			width: 100%;
			max-width: 160px;
			height: 100vh;
			padding: 2% 0 0 0;
			background-color: rgba($color: #FFF, $alpha: 0.9);
			color: #000;
			display: block;
			position: relative;
			.logo {
				width: 70%;
				margin: 0 auto;
				img {
					width: 100%;
				}
			}
			.nav_block {
				width: 70%;
				height: auto;
				margin: auto;
				padding: 60% 0 0 0;
				.nav_list {
					.item {
						margin-bottom: 5px;
						a {
							width: 100%;
							height: 30px;
							font-size: 15px;
							text-align: right;
							padding-right: 18px;
							display: block;
							position: relative;
							letter-spacing: 0.03em;
							&::after {
								content: "";
								width: 0;
								height: 0;
								border-style: solid;
								border-width: 0 0 8px 8px;
								border-color: transparent transparent #333333 transparent;
								position: absolute;
								bottom: 8px;
								right: 0;
								transition: 0.3s;
							}
							span {
								height: 30px;
								line-height: 34px;
								position: absolute;
								top: 50%;
								right: 20px;
								transform: translate(0, -50%);
							}
							.ja {
								transform: translate(-10px, -50%);
								visibility: hidden;
								opacity: 0;
								transition: 0.8s;
							}
							.eng {
								visibility: visible;
								opacity: 1;
								transition: 0.3s;
								line-height: 36px;
							}
							&:hover {
								.ja {
									transform: translate(0, -50%);
									color: $mainColor;
									visibility: visible;
									opacity: 1;
								}
								.eng {
									transform: translate(10px, -50%);
									visibility: hidden;
									opacity: 0;
								}
							}
							&:hover {
								&::after {
									border-color: transparent transparent $mainColor transparent;
									transform: translate(3px, 0);
								}
							}
						}
					}

					&__sub {
						padding-bottom: 3px;
						.sub-item {
							a {
								height: auto;
								padding-right: 0;
								font-size: 10px;
								text-align: left;
								white-space: nowrap;
								&::after {
									display: none;
								}
								&:hover {
									color: $mainColor;
								}
							}
						}
					}
				}
			}
			.contact_block,
			.recruit_block {
				width: 100%;
				position: absolute;
				bottom: 0;
				left: 0;
				.btn_contact {
					width: 100%;
					height: 90px;
					background-color: $subColor;
					font-size: 15px;
					color: #FFF;
					text-align: center;
					display: block;
					position: relative;
					span {
						width: 100%;
						height: 15px;
						line-height: 20px;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}
					.ja {
						transform: translate(-55%, -50%);
						visibility: hidden;
						opacity: 0;
						transition: 0.8s;
					}
					.eng {
						visibility: visible;
						opacity: 1;
						transition: 0.3s;
						line-height: 23px;
					}
					&:hover {
						background-color: $mainColor;
						.ja {
							transform: translate(-50%, -50%);
							visibility: visible;
							opacity: 1;
						}
						.eng {
							transform: translate(-45%, -50%);
							visibility: hidden;
							opacity: 0;
						}
					}
				}
			}
			.recruit_block {
				bottom: 90px;
				.btn_contact {
					background-color: #166684;
				}
			}
		}
		.box {
			width: 100%;
			max-width: 100%;
			height: 100vh;
			padding: 0 0 0 0;
			margin: 0;
			position: relative;
			.title {
				writing-mode: vertical-rl;
				text-orientation: upright;
				position: absolute;
				top: 10%;
				right: 5%;
				font-size: 3.5vw;
				font-weight: 400;
				line-height: 1.4;
				letter-spacing: 0.1em;
			}
			.text {
				position: absolute;
				// bottom: 100px;
				top: 50%;
				left: 100px;
				transform: translate(0, 80%);
				.title_eng {
					font-size: 3vw;
					padding-bottom: 20px;
					color: $mainColor;
					letter-spacing: 0.1em;
					line-height: 1.2;
				}
			}
			.logo {
				width: 100%;
				max-width: 400px;
				position: absolute;
				bottom: -21px;
				left: 100px;
				z-index: 1;
				img {
					width: 100%;
				}
			}
		}
	}
	@include media(small) {
		background-image: url(../images/home/vision/bg_sp.jpg);
		.container {
			display: block;
			.vision_nav {
				display: none;
			}
			.box {
				height: auto;
				padding: 60vw 10% 20%;
				.title {
					right: 10%;
					font-size: 7vw;
				}
				.text {
					position: static;
					transform: translate(0, 0);
					.title_eng {
						font-size: 7vw;
						padding-bottom: 8%;
						line-height: 1.2;
						.sp_block {
							display: block;
							height: 3.6vw;
							font-size: 7vw;
							padding-left: 10.5vw;
							line-height: 5.4vw;
							margin: 1.5vw 0;
							&:nth-child(2) {
								margin-bottom: 2.5vw;
							}
						}
					}
				}
				.logo {
					width: 70%;
					max-width: 100%;
					position: absolute;
					bottom: -1.6%;
					left: 0;
				}
			}
		}
	}
	@media all and (-ms-high-contrast: none) {
		.container {
			.vision_nav {
				max-width: 170px;
			}
		}
	}
}

#service {
	.vertical_message {
		font-size: 12px;
		color: #FFF;
		letter-spacing: 0.2em;
		writing-mode: vertical-rl;
		position: fixed;
		top: 25%;
		right: 2%;
    width: 13px;
    line-height: 14px;
		z-index: 5;
		&::before,
		&::after {
			content: "";
			width: 1px;
			height: 100px;
			background-color: #FFF;
			position: absolute;
			top: -112px;
			left: 6.5px;
		}
		&::after {
			top: auto;
			bottom: -112px;
		}
	}
	.section_title {
		background: $mainColor;
		padding: 119px 0;
		margin: 0;
		.eng {
			font-size: 40px;
			color: #000;
			line-height: 45px;
			height: 35px;
		}
	}
	.container {
		width: 100%;
		max-width: 100%;
		overflow: hidden;
		background: url(../images/home/service/bg_image_01.jpg) no-repeat;
		background-size: auto 60%;
		background-position: right center;
		&::after {
			content: "";
			width: 11%;
			height: 0;
			padding-top: 30%;
			background-image: linear-gradient(110deg, transparent 0%, transparent 50%, rgba($color: #FFF, $alpha: 0.15) 50%, rgba($color: #FFF, $alpha: 0.15) 100%);
			position: absolute;
			bottom: 0;
			right: 0;
			z-index: 0;
		}
		.vertical_parts {
			font-size: 12vh;
			color: #727272;
			letter-spacing: 0.1em;
			writing-mode: vertical-rl;
			position: absolute;
			top: -7px;
			left: -4px;
			width: 10vh;
			line-height: 13vh;
			z-index: 0;
		}
		.box {
			margin-bottom: 0;
			z-index: 2;
			position: relative;
			.content_wrap {
				background-color: rgba($color: #000, $alpha: 0.5);
				padding: 5%;
				width: 75%;
				backdrop-filter: blur(2px);
				.story_title {
					margin-bottom: 40px;
					span {
						display: block;
						position: relative;
					}
					.sub{
						font-size: 24px;
						line-height: 29px;
						height: 24px;
						letter-spacing: 0.05em;
						color: $mainColor;
						padding-left: 70px;
						&::after {
							content: "";
							width: 56px;
							height: 1px;
							background-color: $mainColor;
							position: absolute;
							top: 50%;
							left: 0;
							transform: translate(0, -50%);
						}
					}
					.ja{
						font-size: 46px;
						background: url(../images/home/service/story_title_icon_01.svg) no-repeat;
						background-size: 56px;
						background-position: left center;
						padding: 0 0 0 70px;
					}
					.eng{
						font-size: 20px;
						line-height: 25px;
						height: 20px;
						letter-spacing: 0.05em;
						color: $mainColor;
						padding-left: 70px;
					}
				}
				.detail {
					.subtitle {
						font-size: 24px;
						margin-bottom: 1em;
					}
				}
			}
		}
		&.service_web {
			background-color: $subColor;
			color: #FFF;
		}
		&.service_marketing {
			background-color: #fff0e6;
			background-image: url(../images/home/service/bg_image_03.jpg);
			background-position: left center;
			&::after {
				background-image: linear-gradient(-110deg, transparent 0%, transparent 50%, rgba($color: #FFF, $alpha: 0.35) 50%, rgba($color: #FFF, $alpha: 0.35) 100%);
				right: auto;
				left: 0;
			}
			.vertical_parts {
				color: #ffdac5;
				left: auto;
				right: 0;
			}
			.box {
				.content_wrap {
					background-color: rgba($color: #fdf3ed, $alpha: 0.6);
					margin-left: 25%;
					.story_title {
						.ja{
							background-image: url(../images/home/service/story_title_icon_03.svg);
						}
					}
				}
			}
		}
		&.service_contents {
			background-color: #FFF;
			background-image: url(../images/home/service/bg_image_02.jpg);
			&::after {
				background-image: linear-gradient(110deg, transparent 0%, transparent 50%, rgba($color: #FFF, $alpha: 0.5) 50%, rgba($color: #FFF, $alpha: 0.5) 100%);
			}
			.vertical_parts {
				color: #ffede3;
			}
			.box {
				.content_wrap {
					background-color: rgba($color: #FFF, $alpha: 0.6);
					.story_title {
						.ja{
							background-image: url(../images/home/service/story_title_icon_02.svg);
							background-size: 50px;
						}
					}
				}
			}
		}
	}

	.button-to-page {
		padding-top: 40px;
	}


	@include media(small) {
		.vertical_message {
			font-size: 10px;
			letter-spacing: 0.5em;
			top: -8.3%;
			right: 2%;
			width: 10px;
			line-height: 13px;
			z-index: 2;
			&::before,
			&::after {
				height: 80px;
				top: -90px;
			}
			&::after {
				top: auto;
				bottom: -90px;
			}
		}
		.section_title {
			padding: 16% 0;
			.eng {
				font-size: 8vw;
				color: #000;
				line-height: 9.2vw;
				height: 7.2vw;
			}
		}
		.container {
			padding: 0;
			background-size: cover;
			background-position: center;
			&::after {
				display: none;
			}
			.vertical_parts {
				font-size: 15vw;
				color: rgba($color: #FFF, $alpha: 0.3);
				width: 13vw;
				line-height: 17vw;
				left: auto;
				right: 0;
				z-index: 2;
			}
			.box {
				.content_wrap {
					background-color: rgba($color: #000, $alpha: 0.8);
					padding: 10% 15% 10% 5%;
					width: 100%;
					.story_title {
						margin-bottom: 6%;
						.sub{
							font-size: 5vw;
							line-height: 6vw;
							height: 4.5vw;
							padding-left: 13%;
							&::after {
								width: 10%;
							}
						}
						.ja{
							font-size: 8vw;
							background-size: 10%;
							padding-left: 13%;
						}
						.eng{
							font-size: 4vw;
							line-height: 5vw;
							height: 3.5vw;
							padding-left: 13%;
						}
					}
					.detail {
						.subtitle {
							font-size: 4.2vw;
							margin-bottom: 3%;
						}
					}
				}
			}
			&.service_marketing {
				background-position: center;
				&::after {
					display: none;
				}
				.vertical_parts {
					color: rgba($color: #FFF, $alpha: 0.7);
					left: auto;
					right: 0;
				}
				.box {
					.content_wrap {
						background-color: rgba($color: #ffeee4, $alpha: 0.8);
						margin-left: 0;
						padding: 10% 15% 10% 5%;
					}
				}
			}
			&.service_contents {
				background-position: center;
				&::after {
					display: none;
				}
				.vertical_parts {
					color: rgba($color: #FFF, $alpha: 0.8);
				}
				.box {
					.content_wrap {
						background-color: rgba($color: #FFF, $alpha: 0.8);
						.story_title {
							.ja{
								background-size: 9%;
							}
						}
					}
				}
			}

			.button-to-page {
				padding-top: 26px;
			}
		}
	}
}

#news {
	background-color: #fff0e6;
	&::before {
		content: "";
		width: 40%;
		height: 0;
		padding-top: 40%;
		background-image: linear-gradient(110deg, transparent 0%, transparent 26.7%, #fffdfc 26.7%, #fffdfc 73.2%, transparent 73.2%, transparent 100%);
		position: absolute;
		top: 0;
		left: 0;
	z-index: 0;
	}
	.container {
		.section_title {
		}
		.box {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			.news_item {
				width: 30%;
				background-color: #FFF;
				border-radius: 2px;
				margin-top: 3%;
				margin-bottom: 4%;
				z-index: 1;
				position: relative;
				&:nth-child(1),
				&:nth-child(2),
				&:nth-child(3) {
					margin-top: 0;
				}
				.news_image {
					position: relative;
					overflow: hidden;
					display: flex;
					aspect-ratio: 300 / 220;
					.img {
						width: 100%;
						height: 100%;
						object-fit: cover;
						position: relative;
						z-index: -1;
					}
					.news_date {
						width: auto;
						height: 100%;
						display: inline-block;
						padding: 15px 8px 10px;
						background-color: rgba($color: $mainColor, $alpha: .5);
						color: #FFF;
						font-family: $eng_font;
						margin-left: auto;
						backdrop-filter: blur(1px);
						position: absolute;
						top: 0;
						right: 0;
						span {
							display: block;
						}
						.month ,
						.year {
						}
						.month {
							font-size: 22px;
							height: 22px;
							line-height: 28px;
							text-align: left;
							transform: translate(0, 6px);
							margin-right: 49px;
						}
						.day {
							font-size: 38px;
							height: 38px;
							line-height: 48px;
							margin: 0 0 5px 0;
							text-align: right;
						}
						.year {
							font-size: 26px;
							height: 26px;
							line-height: 33px;
							text-align: right;
						}
					}
				}

				.news_text {
					width: 90%;
					min-height: 156px;
					margin: -23px auto 10px auto;
					background-color: rgba($color: #FFF, $alpha: 0.8);
					padding: 10px 10px;
					z-index: 1;
					position: relative;
					backdrop-filter: blur(1px);
					.title {
						font-size: 16px;
						font-weight: 700;
						line-height: 1.4;
						margin-bottom: 8px;
						display: -webkit-box;
						overflow: hidden;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
					}
					.text {
						display: -webkit-box;
						overflow: hidden;
						-webkit-line-clamp: 3;
						-webkit-box-orient: vertical;
					}
				}
				.btn_more {
					display: none;
					width: 90%;
					margin: 0 auto -23px auto;
					a {
						padding: 12px 17px;
						border-radius: 2px;
						color: #FFF;
						font-size: 13px;
						display: block;
						background: url(../images/common/btn_arrow_white.svg) no-repeat;
						background-size: 26px;
						background-position: right 17px center;
						background-color: $subColor;
						&:hover {
							background-color: $mainColor;
							background-position: right 13px center;
							opacity: 1;
						}
					}
				}
			}
			.btn_news_list {
				display: none;
				width: 100%;
				text-align: right;
				a {
					display: inline-block;
					padding: 2px 32px 2px 8px;
					background: url(../images/common/btn_arrow_black.svg) no-repeat;
					background-size: 20px;
					background-position: right 4px top 9px;
					&:hover {
						background-image: url(../images/common/btn_arrow_main.svg);
						background-position: right 0 top 9px;
						color: $mainColor;
					}
				}
			}
		}
	}

	&.release___202109 {
		.news_text {
			.title,
			.text {
				display: block !important;
				overflow: visible !important;
				-webkit-line-clamp: initial !important;
				-webkit-box-orient: initial !important;
			}
		}
	}

	@include media(small) {
		&::before {
			width: 60%;
			padding-top: 60%;
		}
		.container {
			.section_title {
			}
			.box {
				display: block;
				.news_item {
					width: 90%;
					margin: auto;
					margin-bottom: 15%;
					&:nth-child(1),
					&:nth-child(2),
					&:nth-child(3) {
						margin: auto;
						margin-bottom: 15%;
					}
					.news_image {
						background-size: cover;
						background-position: center;
						display: flex;
						.news_date {
							width: auto;
							height: 100%;
							padding: 5% 3%;
							.month {
								font-size: 5vw;
								height: 4.5vw;
								line-height: 6vw;
								margin-right: 12vw;
								transform: translate(0, 3vw);
							}
							.day {
								font-size: 9vw;
								height: 9vw;
								line-height: 11.5vw;
								margin: 10% 0 14% 0;
							}
							.year {
								font-size: 6vw;
								height: 5.5vw;
								line-height: 7vw;
							}
						}
					}
					.news_text {
						min-height: auto;
						margin: -7% auto 0 auto;
						padding: 4% 5% 11% 5%;
						.title {
							font-size: 4vw;
							margin-bottom: 4%;
						}
						.text {
							line-height: 1.6;
						}
					}
					.btn_more {
						width: 90%;
						margin: 0;
						position: absolute;
						bottom: -7%;
						left: 50%;
						transform: translate(-50%, 0);
						z-index: 2;
						a {
							padding: 3.5% 6.5%;
							font-size: 3vw;
							background-size: 7%;
							background-position: right 7% center;
							&:hover {
								background-color: #000;
								background-position: right 7% center;
							}
						}
					}
				}
				.btn_news_list {
					a {
						font-size: 3.5vw;
						padding: 1% 7%1% 2%;
						background-size: 15%;
						background-position: right 0 top 43%;
						&:hover {
							background-image: url(../images/common/btn_arrow_main.svg);
							background-position: right 0 top 43%;
							color: #000;
						}
					}
				}
			}
		}
	}
}


#about_us {
	background: url(../images/home/about_us/bg.jpg) no-repeat;
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	.container {
		.box {
			.fg_info {
				width: 660px;
				margin: auto;
				.item_block {
					display: flex;
					justify-content: space-between;
					dt ,
					dd {
						display: block;
						border-bottom: 1px solid rgba($color: #000, $alpha: 0.3);
						padding: 30px 5px;
					}
					dt {
						width: 20%;
					}
					dd {
						width: 75%;
					}
					ul {
						li {
							padding-left: 1.4em;
							text-indent: -1.4em;
							margin-bottom: 5px;
							&:last-child {
								margin-bottom: 0;
							}
							&::before {
								content: "●";
								padding-right: 5px;
							}
						}
					}
					&.mark {
						dd {
							padding-top: 10px;
							padding-bottom: 20px;
						}
						.flex_wrap {
							display: flex;
							justify-content: flex-start;
							align-items: center;
							margin-bottom: 20px;
							&:last-child {
								margin-bottom: 0;
							}
							.mark_iso,
							.mark_p {
								display: block;
							}
							.mark_iso {
								width: 160px;
							}
							span {
								display: block;
								margin-left: 20px;
							}
						}
					}
				}
			}
		}
	}
	@include media (small) {
		.container {
			.section_title {
				margin-bottom: 4%;
			}
			.box {
				.fg_info {
					width: 100%;
					.item_block {
						dt ,
						dd {
							padding: 15px 5px;
						}
						dt {
							width: 25%;
						}
						dd {
							width: 70%;
						}
						&.mark {
							.flex_wrap {
								display: block;
								span {
									font-size: 12px;
									margin-top: 5px;
									margin-left: 0;
								}
							}
						}
					}
				}
			}
		}
	}
}


#contact {
	padding-bottom: 60px;
	&::before {
		content: "";
		width: 470px;
		height: 100%;
		background-image: linear-gradient(110deg, transparent 0%, transparent 50%, #ffeae0 50%, #ffeae0 100%);
		position: absolute;
		bottom: 0;
		right: 0;
		z-index: -1;
	}
	&::after {
		content: "";
		width: 840px;
		height: 0;
		padding-top: 840px;
		background-image: linear-gradient(110deg, transparent 0%, transparent 26.7%, rgba($color: #000000, $alpha: 0.05) 26.7%, rgba($color: #000000, $alpha: 0.05) 73.2%, transparent 73.2%, transparent 100%);
		position: absolute;
		bottom: 0;
		right: -100px;
		z-index: -1;
	}
	.container {
		.box {
			width: 660px;
			margin: auto;
			.section_title {
				background: url(../images/common/icon_airplane.svg) no-repeat;
				background-size: 60px;
				background-position: top 15px left;
				text-align: left;
				padding-left: 75px;
				margin-bottom: 1em;
				.ja {
					line-height: 1.6em;
					margin-bottom: 0.4em;
					font-size: 28px;
				}
				.eng {
					font-size: 24px;
				}
			}
			.btn_contact {
				width: 100%;
				margin: 3em auto 0 auto;
				a {
					color: #FFF;
					text-align: center;
					font-size: 22px;
					height: 80px;
					line-height: 86px;
					border-radius: 2px;
					background: url(../images/common/btn_arrow_white.svg) no-repeat;
					background-size: 30px;
					background-position: right 26px center;
					background-color: $mainColor;
					padding: 0 0;
					display: block;
					&:hover {
						background-color: $subColor;
						background-position: right 18px center;
						transition: 0.4s;
					}
				}
			}
		}
	}
	.access {
		width: 100%;
		max-width: 100%;
		padding: 0;
		position: relative;
		box-sizing: border-box;
		.fg_access_info {
			background-color: $subColor;
			color: #FFF;
			padding: 2em 4em;
			border-radius: 2px;
			position: absolute;
			top: 44.5%;
			left: 0;
			transform: translate(0, -50%);
			z-index: 10;
			font-size: 16px;
			p {
				margin: 0;
				padding: 0;
			}
			.title {
				font-size: 24px;
			}
			.name {
				padding: 0 0 5px 30px;
				position: relative;
				&::before {
					content: "";
					width: 20px;
					height: 1px;
					background-color: #FFF;
					position: absolute;
					top: 50%;
					left: 0;
					transform: translate(0, -50%);
				}
			}
			.address {}
		}
		.map_wrap {
			width: 100%;
			height: 580px;
			overflow: hidden;
			position: relative;
			.fg_map {
				display: block;
				width: 100%;
				height: 880px;
				filter: grayscale(1);
				position: absolute;
				top: 50%;
				left: 0;
				transform: translate(0, -50%);
			}
		}
	}
	@include media (small) {
		padding-bottom: 60px;
		&::before {
			width: 104%;
			height: 100%;
			bottom: 0;
			right: 0;
			background-image: linear-gradient(110deg, transparent 0%, transparent 67%, #ffeae0 67%, #ffeae0 100%);
		}
		&::after {
			width: 90%;
			padding-top: 580px;
			right: 0;
			background-image: linear-gradient(110deg, transparent 0%, transparent 41.7%, rgba($color: #000000, $alpha: 0.05) 41.7%, rgba($color: #000000, $alpha: 0.05) 73.2%, transparent 73.2%, transparent 100%);
		}
		.container {
			.box {
				width: 100%;
				.section_title {
					background-size: 9%;
					background-position: top 33% left;
					padding-left: 12%;
					margin-bottom: 0.5em;
					.ja {
						font-size: 4.8vw;
						line-height: 1.4em;
					}
					.eng {
						font-size: 4vw;
					}
				}
				.btn_contact {
					width: 80%;
					margin-top: 2em;
					a {
						font-size: 4vw;
						height: 14vw;
						line-height: 16vw;
						background-size: 8%;
						background-position: right 8% center;
						&:hover {
							background-color: $mainColor;
							background-position: right 8% center;
						}
					}
				}
			}
		}
		.access {
			width: 100%;
			max-width: 100%;
			height: auto;
			padding: 0;
			position: relative;
			box-sizing: border-box;
			.fg_access_info {
				padding: 3% 5% 5% 4%;
				top: -60px;
				transform: translate(0, 0);
				.title {
					font-size: 16px;
				}
				.name {
					font-size: 12px;
					padding: 0 0 3px 22px;
					&::before {
						width: 15px;
					}
				}
				.address {
					font-size: 12px;
					line-height: 1.4;
				}
			}
			.map_wrap {
				height: 400px;
				margin-top: 67px;
				.fg_map {
					height: 610px;
				}
			}
		}
	}
}
