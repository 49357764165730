@charset "utf-8";

/*-------------------------------------------------
logo
-------------------------------------------------*/
.svg__fg_logo03 {
	display: block;
	width: 100%;
	fill: #231815;
	transition: 0.6s;
}


/*-------------------------------------------------
common
-------------------------------------------------*/
.wrapper {
	width: 100%;
	height: auto;
	overflow-x: hidden;
	overflow-y: visible;
	position: relative;
}

#home {
	.wrapper {
		height: auto;
		overflow-y: visible;
		&.open {
		}
	}
}

.pc {
	display: block !important;
	@include media(small) {
		display: none !important;
	}
}
.sp {
	display: none !important;
	@include media(small) {
		display: block !important;
	}
}

.no_attachment_fixed {
	background-attachment: scroll !important;
	background-size: cover !important;
}

.eng {
	font-family: $eng_font;
	text-transform: uppercase;
}

/*-------------------------------------------------
loading
-------------------------------------------------*/
#loading {
	width: 100%;
	height: 100vh;
	background-color: $mainColor;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10010;
	opacity: 1;
	visibility: visible;
	&.hidden_animation {
		opacity: 0;
		visibility: hidden;
		transition: 1.5s;
		transition-delay: 0.5s;
	}

	.logo {
		width: 120px;
		overflow: hidden;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		@include media(small) {
			width: 80px;
			top: 50%;
		}
		.img_logo {
			opacity: 1;
			width: 100%;
		}
		.ground {
			width: 100%;
			height: 8px;
			margin-top: 12px;
			background: url(../images/common/loading/ground_text.svg) repeat-x;
			background-size: cover;
			background-position: center right;
		}
		&.is_loading {
			.img_logo {
				transform: translate(0, 0);
				animation: is_loading 1.1s alternate infinite;
				animation-timing-function: $easeInOutCubic;
				@keyframes is_loading {
					0% {
						transform: translate(0, -24.5px);
					}
					100% {
						transform: translate(0, 0);
					}
				}
			}
			.ground {
				animation: ground_move 18s infinite linear;
				@keyframes ground_move {
					0%{
						background-position: center right;
					}
					100%{
						background-position: center left;
					}
				}
			}
		}
	}
}


/*-------------------------------------------------
animation
-------------------------------------------------*/
// hidden
.hidden {
	opacity: 0;
	transition: all 0.6s;
}
.hidden_slide {
	opacity: 0;
	transform: translateX(50px);
	transition: all 0.6s;
}
.hidden_slide_left {
	opacity: 0;
	transform: translateX(-20px);
	transition: all 0.6s;
}
.hidden_fadeup {
	opacity: 0;
	transform: translateY(30px);
	transition: all 0.6s;
}
.hidden_fadedown {
	opacity: 0;
	transform: translateY(-30px);
	transition: all 0.6s;
}

// show
.show_fadein {
	opacity: 1;
	transition: opacity 0.6s;
}
.show_slide {
	opacity: 1;
	transform: translateX(0);
	transition: all 0.6s;
}
.show_slide_left {
	opacity: 1;
	transform: translateX(0);
	transition: all 0.6s;
}
.show_fadeup {
	opacity: 1;
	transform: translateY(0);
	transition: opacity 1.2s, transform 0.6s;
}
.show_fadedown {
	opacity: 1;
	transform: translateY(0);
	transition: opacity 1.2s, transform 0.6s;
}

// delay
.delay_200 {
	transition-delay: 0.2s;
}
.delay_400 {
	transition-delay: 0.4s;
}
.delay_600 {
	transition-delay: 0.6s;
}
.delay_800 {
	transition-delay: 0.8s;
}
.delay_1000 {
	transition-delay: 1s;
}
.delay_1200 {
	transition-delay: 1.2s;
}
.delay_1400 {
	transition-delay: 1.4s;
}
.delay_1600 {
	transition-delay: 1.6s;
}
.delay_1800 {
	transition-delay: 1.8s;
}
.delay_2000 {
	transition-delay: 2s;
}
.delay_2200 {
	transition-delay: 2.2s;
}
.delay_2400 {
	transition-delay: 2.4s;
}
.delay_2800 {
	transition-delay: 2.8s;
}
.delay_3200 {
	transition-delay: 3.2s;
}
.delay_4000 {
	transition-delay: 4s;
}

/*-------------------------------------------------
animation end
-------------------------------------------------*/

main {
	.section {
		position: relative;
		.section_title {
			font-size: 36px;
			color: #000;
			letter-spacing: 0.08em;
			text-align: center;
			margin-bottom: 1.8em;
			.ja {
				display: block;
				font-size: 30px;
				margin-bottom: 0.2em;
			}
			.eng {
				display: block;
				font-size: 30px;
				color: $mainColor;
				line-height: 1.2;
			}
		}
	}
	.container {
		width: 95%;
		max-width: $containerWidth;
		margin: 0 auto;
		padding: 120px 0;
		position: relative;

		.box {
			width: 100%;
			max-width: $innerWidth;
			margin: 0 auto 2em auto;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	@include media(small) {
		.section {
			.section_title {
				margin-bottom: 8%;
				.ja {
					font-size: 6.5vw;
					margin-bottom: 0.4vw;
				}
				.eng {
					font-size: 4.5vw;
				}
			}
		}
		.container {
			width: 90%;
			padding: 10% 0;

			.box {
				margin-bottom: 8%;
			}
		}
	}
}

// text shadow
$shadowVertical: 0px;
$shadowSide: 0px;
$shadowBlur: 20px;
$shadowColor: rgba(0, 0, 0, 0.3);
.text_shadow {
	text-shadow: $shadowVertical $shadowSide $shadowBlur $shadowColor, (-$shadowVertical) (-$shadowSide) $shadowBlur $shadowColor, (-$shadowVertical) $shadowSide $shadowBlur $shadowColor, $shadowVertical (-$shadowSide) $shadowBlur $shadowColor;
}

.btn_box {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-items: center;
	padding: 2em 0 0;
	li {
		margin: 0 1em 30px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	@include media(small) {
		li {
			margin: 0 0 6%;
			width: 100%;
		}
	}
}
.btn_base {
	display: block;
	width: 350px;
	margin: auto;
	color: $btnText;
	font-size: 20px;
	font-weight: 500;
	text-align: center;
	border: 1px solid #000;
	border-radius: 100vh;
	background-color: $btnColor;
	padding: 11px 0;
	position: relative;
	overflow: hidden;
	z-index: 2;
	transition: all 0.3s;
	transition-timing-function: $easeInOutCubic;
	&::before {
		content: '';
		width: 0;
		height: 100%;
		background-color: $btnColorHover;
		position: absolute;
		top: 0;
		right: 0;
		z-index: -1;
		transition: all 0.2s;
		transition-timing-function: $easeInOutCubic;
	}
	&::after {
		content: '';
		width: 16px;
		height: 16px;
		position: absolute;
		top: 50%;
		right: 5%;
		transform: translate(0px, -50%);
		background: url(../images/common/btn_arrow_black.svg) no-repeat;
		background-size: contain;
		background-position: center;
		transition: all 0.3s;
		transition-timing-function: $easeInOutCubic;
	}
	&:hover {
		color: $btnTextHover;
		transition: all 0.3s;
		transition-timing-function: $easeInOutCubic;
		&::before {
			content: '';
			width: 100%;
			right: auto;
			left: 0;
			transition: all 0.3s;
			transition-timing-function: $easeInOutCubic;
		}
		&::after {
			transform: translate(5px, -50%);
			background-image: url(../images/common/btn_arrow_white.svg);
			transition: all 0.5s;
			transition-timing-function: $easeInOutCubic;
		}
	}
	@include media(small) {
		width: 100%;
		font-size: 1em;
		&::after {
			width: 13px;
			height: 13px;
		}
		&:hover {
			color: $btnText;
			background-color: $btnColor;
			transition: none;
			&::before {
				display: none;
			}
			&::after {
				transform: translate(0, -50%);
				background-image: url(../images/common/btn_arrow_black.svg);
			}
		}
	}
}

.hover__underline {
	display: block;
	position: relative;
	&::after {
		content: "";
		width: 0%;
		height: 1px;
		background-color: rgba($color: #FFF, $alpha: 0.6);
		position: absolute;
		bottom: -2px;
		left: auto;
		right: 0;
		transition: 0.2s;
	}
	&:hover {
		&::after {
			width: 100%;
			left: 0;
			right: auto;
			transition: 0.3s;
		}
	}
	@include media(small) {
		&:hover {
			&::after {
				width: 0%;
			}
		}
	}
}
