@charset "utf-8";

body {
	background: #FFF;
	color: #000;
	font-size: 14px;
	line-height: 1.8;
	width: 100%;
	font-family: $base_font;
	font-weight: 400;
	@include media(small) {
		font-size: 14px;
	}
}

input,
textarea {
	font-family: $base_font;
}

p {
	padding-bottom: 1em;
	line-height: 2;
	letter-spacing: 0.08em;
	&:last-of-type {
		padding-bottom: 0;
	}
	@include media(small) {
		font-size: 14px;
	}
}

a {
	color: #000;
	font-size: 1em;
	outline: none;
	text-decoration: none;
	transition: all 0.2s linear;
	&:link,
	&:visited,
	&:hover,
	&:active {
		color: #000;
	}
}

sup {
	vertical-align: super;
	font-size: smaller;
}

@media (min-width: 768px) {
	a[href^='tel:'] {
		pointer-events: none;
	}
}
