@charset "utf-8";

/*----------------------------------------------------
	nav
----------------------------------------------------*/

.nav_trigger {
	width: 100%;
	max-width: 160px;
	height: 90px;
	background-color: $subColor;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	transform: translate(0, -50px);
	transition: 0.3s;
	z-index: 9999;
	opacity: 0;
	visibility: hidden;
	&.show ,
	&.active {
		opacity: 1;
		visibility: visible;
		position: fixed;
		transform: translate(0, 0);
		opacity: 1;
		visibility: visible;
	}
	.inner {
		width: 40px;
	}
	.text {
		display: block;
		color: #FFF;
		text-align: center;
		font-size: 11px;
		line-height: 12px;
		letter-spacing: 0.1em;
	}
	.line {
		display: block;
		width: 100%;
		height: 1px;
		background-color: #FFF;
		transition: all 0.4s;
		transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
		&.top {
			margin: 4px 0 7px 0;
		}
		&.bottom {
		}
	}
	&:hover {
		background-color: $mainColor;
		transition: 0.3s;
		transition-delay: 0s;
		.line {
			&.top {
				transform: translateY(6px);
			}
			&.bottom {
				transform: translateY(-6px);
			}
		}
	}
	&.active {
		background-color: $mainColor;
		span {
			&.top {
				transform: rotate(45deg) translate(15px, 3px);
				width: 80%;
			}
			&.bottom {
				transform: rotate(-45deg) translate(2px, 9px);
				width: 80%;
			}
		}
		&:hover {
			background-color: $mainColor;
			opacity: 1;
			.line {
				&.top {
					transform: rotate(30deg) translate(13px, 4px);
				}
				&.bottom {
					transform: rotate(-30deg) translate(5px, 6px);
				}
			}
		}
	}
	@include media(small) {
		width: 75px;
		height: 55px;
		.inner {
			width: 36px;
		}
		.text {
			font-size: 10px;
			line-height: 10px;
		}
		.line {
			&.top {
				margin-top: 5px;
			}
		}
		&:hover {
			background-color: #000;
			.line {
				&.top {
					transform: translateY(0);
				}
				&.bottom {
					transform: translateY(0);
				}
			}
		}
		&.active {
			background-color: $mainColor;
			span {
				&.top {
					transform: rotate(45deg) translate(13px, 3px);
				}
				&.bottom {
					transform: rotate(-45deg) translate(2px, 7px);
				}
			}
			&:hover {
				.line {
					&.top {
						transform: rotate(45deg) translate(13px, 3px);
					}
					&.bottom {
						transform: rotate(-45deg) translate(2px, 7px);
					}
				}
			}
		}
	}
	@media all and (-ms-high-contrast: none) {
	}
}

.nav {
	width: 100%;
	height: 100vh;
	background-color: rgba($color: #000000, $alpha: 0.5);
	position: fixed;
	display: flex;
	justify-content: space-between;
	opacity: 0;
	visibility: hidden;
	transition: all 0.6s;
	z-index: 9998;
	pointer-events: none;
	.nav_block {
		width: 50%;
		height: 66vh;
		background-color: $mainColor;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		.nav_list {
			.item {
				margin-bottom: 2em;
				a {
					width: 230px;
					height: 50px;
					font-size: 36px;
					display: block;
					position: relative;
					span {
						height: 50px;
						line-height: 59px;
						position: absolute;
						top: 50%;
						left: 0;
						transform: translate(0, -50%);
					}
					.ja {
						transform: translate(-10px, -50%);
						visibility: hidden;
						opacity: 0;
						font-size: 32px;
						line-height: 50px;
						transition: 0.8s;
					}
					.eng {
						visibility: visible;
						opacity: 1;
						font-weight: 700;
						letter-spacing: 0.05em;
						transition: 0.3s;
					}
					&:hover {
						.ja {
							transform: translate(0, -50%);
							visibility: visible;
							opacity: 1;
						}
						.eng {
							transform: translate(10px, -50%);
							visibility: hidden;
							opacity: 0;
						}
					}
				}
			}
			&.active {
			}
			&__sub {
				.sub-item {
					a {
						height: auto;
						padding-right: 0;
						font-size: 18px;
						text-align: left;
						white-space: nowrap;
						transform: translateX(0);
						transition: 0.5s;
						&:hover {
							transform: translateX(10px);
						}
					}
				}
			}
		}
	}
	.contact_block,
	.recruit_block {
		width: 50%;
		position: absolute;
		bottom: 0;
		left: 0;
		.btn_contact {
			width: 100%;
			// height: 20vh;
			height: 17vh;
			background-color: #000;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 36px;
			color: #FFF;
			.icon_wrap {
				display: block;
				position: relative;
				&::after {
					content: "";
					display: block;
					width: 50px;
					height: 58px;
					background: url(../images/common/icon_airplane_white.svg) no-repeat;
					background-size: 100%;
					background-position: center;
					position: absolute;
					top: 50%;
					left: -67px;
					transform: translate(0, -50%);
					transition: 0.8s;
				}
			}
			span {
				height: 36px;
				line-height: 44px;
				width: 215px;
				position: relative;
			}
			.ja ,
			.eng {
				position: absolute;
				top: 0;
				left: 0;
			}
			.ja {
				transform: translate(-10px, 0);
				visibility: hidden;
				opacity: 0;
				font-size: 32px;
				line-height: 30px;
				transition: 0.8s;
			}
			.eng {
				visibility: visible;
				opacity: 1;
				letter-spacing: 0.05em;
				transition: 0.3s;
			}
			&:hover {
				.icon_wrap {
					&::after {
						transform: translate(5px, -63%);
					}
				}
				.ja {
					transform: translate(0, 0);
					visibility: visible;
					opacity: 1;
				}
				.eng {
					transform: translate(10px, 0);
					visibility: hidden;
					opacity: 0;
				}
			}
		}
	}
	.recruit_block {
		bottom: 17vh;
		.btn_contact {
			background-color: #166684;
			.icon_wrap {
				&::after {
					background-image: url(../images/common/icon_recruit_white.svg);
				}
			}
		}
	}
	.logo_block {
		width: 50%;
		height: 100vh;
		background-color: #FFF;
		display: flex;
		justify-content: center;
		align-items: center;
		.inner {
			p {
				font-size: 24px;
				padding-bottom: 1em;
			}
			.logo_wrap {
				width: 80%;
				margin: auto;
				display: block;
				transition: 0.4s;
				.svg__fg_logo03 {
				}
				&:hover {
					.svg__fg_logo03 {
						fill: $mainColor;
						transition: 0.3s;
					}
				}
			}
		}
	}
	// nav active
	.nav_block,
	.contact_block,
	.recruit_block,
	.logo_block {
		opacity: 0;
		transition: 0.2s;
	}
	.nav_block {
		transform: translate(-40px, 0);
	}
	.contact_block {
		transform: translate(0, 40px);
	}
	.recruit_block {
		transform: translate(-70px, 0);
	}
	.logo_block {
		transform: translate(40px, 0);
	}
	&.active {
		opacity: 1;
		visibility: visible;
		transition: all 0.6s;
		pointer-events: auto;
		.nav_block,
		.contact_block,
		.recruit_block,
		.logo_block {
			opacity: 1;
			transition: 0.8s;
		}
		.nav_block {
			transform: translate(0, 0);
			transition-delay: 0.1s;
		}
		.contact_block,
		.recruit_block {
			transform: translate(0, 0);
			transition-delay: 0.3s;
		}
		.logo_block {
			transform: translate(0, 0);
			transition-delay: 0.3s;
		}
	}

	@include media(small) {
		&.active {
			display: block;
		}
		.nav_block {
			width: 100%;
			height: 61vh;
			.nav_list {
				.item {
					margin-bottom: 3vh;
					&:last-child {
						margin-bottom: 0;
					}
					a {
						width: 40vh;
						height: 6vh;
						font-size: 4vh;
						span {
							height: 6vh;
							line-height: 7vh;
						}
						.ja {
							display: none;
						}
						.eng {
							width: 100%;
							text-align: center;
							display: block;
						}
						&:hover {
							.ja {
								visibility: hidden;
								opacity: 0;
							}
							.eng {
								transform: translate(0, -50%);
								visibility: visible;
								opacity: 1;
							}
						}
					}
				}
				&__sub {
					.sub-item {
						a {
							height: auto;
							font-size: 2.2vh;
							text-align: center;
							&:hover {
								transform: translateX(0);
							}
						}
					}
				}
			}
		}
		.contact_block,
		.recruit_block {
			width: 100%;
			height: auto;
			position: static;
			.btn_contact {
				font-size: 4vh;
				height: 12vh;
				.icon_wrap {
					margin-left: 6vh;
					&::after {
						width: 5vh;
						height: 5vh;
						left: -7vh;
					}
				}
				span {
					height: 6vh;
					line-height: 7vh;
					width: 24vh;
				}
				.ja {
					display: none;
				}
				&:hover {
					.icon_wrap {
						&::after {
							transform: translate(0, -50%);
						}
					}
					.ja {
						visibility: hidden;
						opacity: 0;
					}
					.eng {
						transform: translate(0, 0);
						visibility: visible;
						opacity: 1;
					}
				}
			}
		}
		.recruit_block {
			.btn_contact {
				.icon_wrap {
					&::after {
						width: 5.2vh;
						height: 5.2vh;
						background-size: auto 100%;
					}
				}
			}
		}
		.logo_block {
			width: 100%;
			height: 15vh;
			.inner {
				p {
					font-size: 1.8vh;
					padding-bottom: 1vh;
				}
				.logo_wrap {
					width: 13vh;
					&:hover {
						.svg__fg_logo03 {
							fill: #231815;
						}
					}
				}
			}
		}
	}
}
