@charset "utf-8";

@mixin media($breakpoint: large) {

	@if $breakpoint == small {
		@media screen and (max-width: 850px) {
			@content;
		}
	}

	@else if $breakpoint == middle {
		@media screen and (max-width: 1024px) {
			@content;
		}
	}

	@else if $breakpoint == large {
		@media screen and (min-width: 851px) {
			@content;
		}
	}

	@else if $breakpoint == full {
		@media screen and (min-width: 1600px) {
			@content;
		}
	}
}
