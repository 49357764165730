@charset "utf-8";

.page main:has(.c-service-navigation) {
	padding-top: 90px;
}

.c-service-navigation {
	background-color: #FFD7C5;
	padding: 30px 0;
	z-index: 1;
	position: relative;
	.navigation-list {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 70px;
		.navigation-item {
			width: 280px;
			a {
				display: block;
				background-image: none;
				background-repeat: no-repeat;
				background-size: 100% auto;
				background-position: center;
				transition: 0s;
				img {
					width: 100%;
					height: auto;
					opacity: 1;
					transition: 0s;
				}
				&.current {
					img {
						opacity: 0;
					}
				}
				&:hover {
					transition: 0.3s;
					img {
						opacity: 0;
						transition: 0.3s;
					}
				}
			}
			&.nav-website-construction {
				a {
					&:hover {
						background-image: url(../../assets/images/pages/service/nav-item01-hover.png);
					}
					&.current {
						background-image: url(../../assets/images/pages/service/nav-item01-hover.png);
					}
				}
			}
			&.nav-marketing {
				a {
					&:hover {
						background-image: url(../../assets/images/pages/service/nav-item02-hover.png);
					}
					&.current {
						background-image: url(../../assets/images/pages/service/nav-item02-hover.png);
					}
				}
			}
			&.nav-content-production {
				a {
					&:hover {
						background-image: url(../../assets/images/pages/service/nav-item03-hover.png);
					}
					&.current {
						background-image: url(../../assets/images/pages/service/nav-item03-hover.png);
					}
				}
			}
		}
	}
}
@include media (small) {
	.page main:has(.c-service-navigation) {
		padding-top: 55px;
	}

	.c-service-navigation {
		padding: 5% 3%;
		.navigation-list {
			justify-content: center;
			gap: 3%;
			.navigation-item {
				width: 30%;
				a {
					&:hover {
						img {
							opacity: 1;
						}
					}
				}
				&.nav-website-construction {
					a {
						&:hover {
							background-image: url(../../assets/images/pages/service/nav-item01.png);
						}
					}
				}
				&.nav-marketing {
					a {
						&:hover {
							background-image: url(../../assets/images/pages/service/nav-item02.png);
						}
					}
				}
				&.nav-content-production {
					a {
						&:hover {
							background-image: url(../../assets/images/pages/service/nav-item03.png);
						}
					}
				}
			}
		}
	}
}