@charset "utf-8";

/*----------------------------------------------------
	HubSpotForm
----------------------------------------------------*/
.hubspot_form {
	width: 90%;
	margin: auto;
}
@include media (small) {
}


/*----------------------------------------------------
	form
----------------------------------------------------*/
.form {
	.container {
		.box {
			.text {
				text-align: center;
				margin-bottom: 4em;
			}
		}
	}
}
@include media (small) {
	.form {
		.container {
			.box {
				.text {
					text-align: center;
					margin-bottom: 2em;
				}
			}
		}
	}
}

